.add-module-modal {
  .modal-body {
    @extend .p-0;
  }

  .module-list-tabs-container {
    @extend .p-0;

    background-color: $tabs;

    .nav-item {
      border-bottom: 1px solid $tabsHover;

      &:last-child {
        border-bottom: none;
      }

      .nav-link {
        padding: $spacer * 1.25 $spacer * 1.5;
        color: $medium;
        font-weight: 600;
        font-size: $spacer * 0.875;
        letter-spacing: 1.75px;

        &.active,
        &:hover {
          background-color: $tabsHover;
        }

        &.disabled {
          color: $input;
        }
      }
    }
  }

  .module-list-module-container {
    background-color: $white;
    padding: $spacer * 3;

    .module-list-module-container-header {
      @extend .d-flex, .justify-content-between, .mb-4;
    }

    @include media-breakpoint-down(md) {
      padding: $spacer;
    }
  }
}
