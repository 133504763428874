header.banner {
  position: sticky;
  top: 0;
  z-index: 10;
  nav.navbar {
    padding: $spacer;
  }

  .presentation-btn {
    @extend .d-none, .d-md-flex, .align-items-center, .mx-3, .text-white;

    text-transform: uppercase;
    background-color: $primary;
    padding: $spacer * 0.5 $spacer * 2;
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($primary, 5%);
    }
  }

  .nav-item {
    @extend .d-flex, .align-items-center;
  }

  .dropdown-menu[data-bs-popper] {
    left: auto !important;
    right: 0 !important;
  }

  .dropdown-toggle::after {
    margin-left: $spacer * 0.75;
    vertical-align: middle;
    border-top: 0.3em solid $white;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .user-img-button {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-radius: 50%;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .nav-logout-button {
    @extend .dropdown-item, .p-0;

    text-transform: capitalize;
  }
}
