.rws-radio-group {
  .form-check {
    @extend .py-0;

    &.form-check-fh {
      height: 50px;
    }
  }
}
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;

//   .label {
//     display: flex;
//     cursor: pointer;
//     font-weight: 500;
//     position: relative;
//     overflow: hidden;
//     margin-bottom: 0;
//     /* Accessible outline */
//     /* Remove comment to use */
//     /*
//       &:focus-within {
//           outline: .125rem solid $primary;
//       }
//     */
//     input.rws-radio-button {
//       position: absolute;
//       left: -9999px;
//       &:checked + span {
//         &:before {
//           border: none;
//           box-shadow: inset 0 0 0 0.75rem $primary;
//         }
//       }
//     }
//     span {
//       display: flex;
//       align-items: center;
//       padding: 0.5rem 0.5rem 0.5rem 0.5rem;
//       // border-radius: 99rem;
//       // transition: 0.25s ease;
//       // &:hover {
//       //   background-color: mix(#fff, $primary, 84%);
//       // }
//       &:before {
//         display: flex;
//         flex-shrink: 0;
//         content: "";
//         background-color: #fff;
//         width: 1.5rem;
//         height: 1.5rem;
//         border: 1px solid $input;
//         border-radius: 50%;
//         margin-right: 0.375rem;
//         transition: 0.25s ease;
//         box-shadow: unset;
//       }

//       p {
//         @extend .mb-0;
//       }
//     }
//   }
// }
