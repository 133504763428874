.editor-select {
  @extend .mb-3;

  position: relative;

  .editor-select-dropdown {
    right: 0;
    left: 0;

    .editor-select-dropdown-item {
      @extend .d-flex, .align-items-center;

      .editor-select-dropdown-item-image {
        width: $spacer * 4;
        text-align: center;

        img {
          border-radius: 50%;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .editor-select-dropdown-item-name {
        p {
          @extend .mb-0;
        }
      }
    }
  }
}
