.mobile-main-nav {
  @extend .bg-dark;
  margin-top: 102px;
  border-right: 0 !important;

  .offcanvas-body {
    @extend .px-0;

    .nav {
      @extend .d-flex, .flex-column;

      > * {
        height: 56px;
      }

      .menu-control {
        @extend .d-none;
      }

      .create-new-link {
        @extend .text-white, .nav-link, .btn;

        background-color: $primary;
        font-size: $spacer * 0.875;
        font-family: "Montserrat";
        font-weight: 500;
        width: 100%;
        border: none;

        &:focus {
          border: none;
          outline: none;
        }

        svg {
          display: none;
        }

        .btn-span {
          @extend .mx-auto;
        }
      }

      .nav-link {
        @extend .text-white,
          .nav-font,
          .d-flex,
          .justify-content-start,
          .align-items-center;

        padding: $spacer * 1.25 $spacer * 1.5;
        width: 100%;
        position: relative;

        &.active {
          .span {
            font-weight: 800;
          }

          &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $secondary;
          }
        }

        &.active,
        &.open,
        &:hover {
          background-color: $activeDark;
        }

        .span {
          width: 175px;
          margin-left: $spacer * 1.25;
        }
      }
    }
  }
}
