.media-library-component {
  .nav-tabs {
    @extend .mb-0;

    border-bottom: 1px solid $input;

    .nav-link {
      @extend .h4, .mb-0;

      text-transform: uppercase;

      &.active {
        border-color: $primary !important;
        background-color: $primary;
        color: $white;
      }
    }
  }

  .tab-content {
    border-left: 1px solid $input;
    border-bottom: 1px solid $input;
    border-right: 1px solid $input;
  }

  .tab-content {
    padding: $spacer*2;
  }
}