.presentation-editor {
  position: relative;
  .presentation-editor-title {
    margin-bottom: $spacer;
  }

  .presentation-module-list {
    ul {
      overflow: auto;
      list-style: none;
      padding-left: 0;

      .presentation-edit-module {
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 1.5;
      }
    }
  }
}

.editor-cols-2 {
  @extend .d-flex, .flex-column, .flex-md-row;

  .editor-col-2 {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
}

.editor-module {
  .editor-module-field-label {
    // font-weight: 300;
  }

  .editor-module-selected-list {
    height: $spacer * 10.5;
    border: 1px solid $listBorder;

    .editor-module-selected-list-inner {
      height: 100%;
      padding: $spacer;
      overflow-y: scroll;
      overflow-x: hidden;
      word-wrap: break-word;
    }

    .editor-module-selected-list-inner-item {
      @extend .d-flex, .justify-content-between;

      padding: $spacer * 0.5 $spacer;

      .editor-module-selected-list-item-image {
        width: $spacer * 4;
        text-align: center;

        img {
          border-radius: 50%;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .editor-module-selected-list-item-name {
        p {
          @extend .mb-0;
        }
      }
    }
  }
}
