.dashboard-legend {
  @extend .py-3;
  display: flex;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .legend {
    @extend .py-2;
    position: relative;
    margin-right: $spacer * 2;
    flex-grow: 1;

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }

    &.legend-scheduled {
      border-top: $spacer $secondary solid;
    }

    &.legend-expired {
      border-top: $spacer $primary solid;
    }

    &.legend-expires {
      border-top: $spacer $tertiary solid;
    }

    &.legend-never-expires {
      border-top: $spacer $quartary solid;
    }

    &.legend-draft {
      border-top: $spacer $medium solid;
    }
  }
}
