.collection-list {
  .collection-list-card {
    @extend .d-flex, .flex-column, .flex-lg-row;
    width: 100%;
    position: relative;
    padding-top: $spacer;
    padding-bottom: $spacer;
    flex-grow: 1;
    border-bottom: 1px solid $listBorder;

    @include media-breakpoint-up(lg) {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }
  }

  .collection-list-col {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  p {
    overflow-wrap: anywhere;
  }

  .collection-list-title {
    display: inline-flex;
    align-items: start;
    justify-content: space-between;
    flex-grow: 1;
    font-size: $spacer;
    margin-bottom: $spacer;

    h5 {
      margin-bottom: 0;
      padding-right: $spacer;
      font-size: $spacer;
      word-wrap: break-word;
      font-weight: 600;
    }
  }

  .collection-list-image {
    padding-right: $spacer;
    margin-bottom: $spacer;
  }

  .collection-list-info {
    flex: 2;
    padding-right: 48px;

    @include media-breakpoint-down(sm) {
      flex: unset;
      padding-right: 0;
      display: inline-block;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      padding-right: $spacer;
    }
  }

  .collection-list-textarea {
    width: 100%;
  }
}

.collection-list-dropdown {
  .dropdown-toggle {
    @extend .p-0;

    margin-right: 50px;
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 300px;
    padding: $spacer;
    inset: unset;
    border: none;
    box-shadow: $box-shadow;

    @include media-breakpoint-up(lg) {
      transform: 0 !important;
      right: -90px !important;
      left: unset !important;
      width: 300px;
    }

    .list-arrow {
      @include media-breakpoint-up(lg) {
        -webkit-filter: drop-shadow(-2px -2px 3px rgba(0, 0, 0, 0.05));
        filter: drop-shadow(-2px -2px 3px rgba(0, 0, 0, 0.05));
        width: 0;
        height: 0;
        transform: rotate(45deg);
        border-style: solid;
        border-width: 32px 32px 0 0;
        border-color: #fff transparent transparent transparent;
        right: 82px;
        top: -16px;
        position: absolute;
      }
    }
  }

  //   &::after,
  //   &::before {
  //     bottom: 100%;
  //     right: 90px;
  //     transform: translateX(25%);
  //     border: solid transparent;
  //     content: "";
  //     height: 0;
  //     width: 0;
  //     position: absolute;
  //     pointer-events: none;
  //   }

  //   &::after {
  //     border-bottom-color: $white;
  //     border-width: 15px;
  //     margin-left: -15px;
  //   }
  //   &::before {
  //     border-bottom-color: $white;
  //     border-width: 16px;
  //     margin-left: -16px;
  //   }
  // }
}

// .popover.show {
//   left: -46px !important;

//   .popover-body {
//     border: none;
//   }
// }

.collection-list-buttons {
  @extend .d-flex, .flex-column;

  > * {
    @extend .text-dark;
    margin-bottom: $spacer * 0.5;
    margin-top: $spacer * 0.5;
  }
  a {
    color: $dark;
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
    padding-left: $spacer;
  }
}

// .dropdown-menu {
//   width: 300px;
//   padding: $spacer;
//   inset: unset;
//   border: 0;
//   top: $spacer !important;
//   transform: 0 !important;
//   box-shadow: $box-shadow;
//   position: relative;
//   z-index: 10;

//   @include media-breakpoint-up(lg) {
//     right: -90px !important;
//     left: unset !important;
//   }

//   .list-arrow {
//     height: 16px;
//     width: 16px;
//     background-color: $white;
//     box-shadow: $box-shadow;
//     right: 90px;
//     top: -8px;
//     position: absolute;
//     transform: rotate(-45deg);
//     z-index: -10;
//   }
// }
