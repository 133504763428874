.dashboard-filter-wrapper {
  @extend .component-card, .bg-white, .mb-4;
  border-radius: $border-radius;

  .dashboard-search {
    // margin-bottom: $spacer;
  }

  .dashboard-view-by {
    margin-bottom: $spacer * 1.5;

    .filter-by-selectors {
      @extend .d-flex, .align-items-center;

      flex-wrap: wrap;

      .filter-title {
        @extend .mb-0, .me-3;
      }

      .filters {
        @extend .mb-0, .me-3, .pe-3, .ps-0, .text-dark;

        border-right: 1px solid $input;

        &:last-child {
          border-right: none;
        }

        &.active,
        &:hover {
          @extend .text-primary;
        }

        span {
          @extend .ms-1;
        }
      }
    }
  }

  .dashboard-sort-by {
    margin-bottom: $spacer * 2.5;

    .sort-by-selectors {
      @extend .d-flex, .align-items-center;

      p {
        @extend .mb-0;
      }
    }
  }
}
