#fourohfour {
  height: 100vh;
  position: relative;
  overflow: hidden;

  #bloc404 {
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .bryn {
    position: relative;
    @include media-breakpoint-down(sm) {
      top: 5rem;
      left: 1rem;
    }
    @include media-breakpoint-up(md) {
      left: -7rem;
    }
    @include media-breakpoint-up(xl) {
    }
    @media (min-width: 1700px) {
    }
  }
  .error-bryn {
    .brynBody {
      @include media-breakpoint-down(sm) {
        width: 18rem;
      }
      @include media-breakpoint-up(md) {
        position: relative;
        left: 14rem;
      }
    }
    .wiggle {
      animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
      animation-duration: 2.5s; /* Change to speed up or slow down */
      animation-iteration-count: infinite; /* Never stop waving :) */
      transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
      display: inline-block;
      position: relative;

      @include media-breakpoint-down(md) {
        top: -2rem;
        left: -3rem;
        width: 4rem;
      }
      @include media-breakpoint-up(md) {
        left: 9rem;
        top: -1rem;
      }

      @media (min-width: 1700px) {
        top: 0;
      }
    }

    @keyframes wave-animation {
      0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(14deg);
      } /* The following five values can be played with to make the waving more or less extreme */
      20% {
        transform: rotate(-8deg);
      }
      30% {
        transform: rotate(14deg);
      }
      40% {
        transform: rotate(-4deg);
      }
      50% {
        transform: rotate(10deg);
      }
      60% {
        transform: rotate(0deg);
      } /* Reset for the last half to pause */
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .error-raptor {
    .claw1 {
      position: relative;
      top: -15rem;
      transform: rotate(95deg);
    }
  }

  .error-text-bryn {
    position: relative;
    font-family: "Montserrat", sans-serif;
    span {
      font-size: 36px;
      font-weight: 200;
      display: block;
      margin-bottom: 2rem;
    }
    h1 {
      font-weight: 800;
      font-size: 60px;
      margin-bottom: 0;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 3rem;
    }
    .btn {
      font-weight: 500;
      font-size: 12px;
      border-radius: 0.25rem !important;
    }
    @include media-breakpoint-up(md) {
      top: 1rem;
      left: 38%;
      h1 {
        line-height: 3rem;
      }
    }
    @include media-breakpoint-down(sm) {
      top: 1rem;
      left: 1rem;
      h1 {
        line-height: 3rem;
      }
    }

    @include media-breakpoint-up(xxl) {
      top: 7rem;
    }
    @media (min-width: 1700px) {
      left: 8.5rem;
      top: 0;
      top: 7rem;
    }
  }

  .error-text-raptor {
    position: relative;
    top: 2rem;
    font-family: "Montserrat", sans-serif;
    span {
      font-size: 36px;
      font-weight: 200;
      display: block;
      margin-bottom: 2rem;
    }
    h1 {
      font-weight: 800;
      font-size: 60px;
      margin-bottom: 2rem;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 2rem;
    }
    .btn {
      font-weight: 500;
      font-size: 12px;
      border-radius: 0.25rem !important;
    }
    .claw2 {
      position: relative;
      left: 5rem;
      top: 5rem;
    }
  }
}

#frontend-fourohfour {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 25vh;
    margin-bottom: $spacer * 3;
    font-size: $spacer * 4.5;
  }

  h3 {
    font-size: $spacer * 1.5;
  }
}
