.rw_media_tabs {
  display: flex;

  .rw_media_tab {
    flex: 1;
    width: 100%;
    background-color: $tabs_background;
    border: $tabs_border;
    text-align: center;
    padding: $tabs_padding;
    font-weight: $tabs_font_weight;

    &.active {
      background-color: $tabs_background_hover;
    }

    &:hover {
      background-color: $tabs_background_hover;
    }
  }

  @include tablet {
    .rw_media_tab {
      padding: 1rem;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}
