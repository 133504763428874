.rw_media_upload {
  position: relative;
  width: 100%;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: repeating-linear-gradient(
      0deg,
      #c5c5c5,
      #c5c5c5 8px,
      transparent 8px,
      transparent 18px,
      #c5c5c5 18px
    ),
    repeating-linear-gradient(
      90deg,
      #c5c5c5,
      #c5c5c5 8px,
      transparent 8px,
      transparent 18px,
      #c5c5c5 18px
    ),
    repeating-linear-gradient(
      180deg,
      #c5c5c5,
      #c5c5c5 8px,
      transparent 8px,
      transparent 18px,
      #c5c5c5 18px
    ),
    repeating-linear-gradient(
      270deg,
      #c5c5c5,
      #c5c5c5 8px,
      transparent 8px,
      transparent 18px,
      #c5c5c5 18px
    );
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;

  .rw_media_upload_text {
    font-size: 1.5rem;
    font-family: inherit;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
  }

  .rw_media_upload_input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .rw_media_upload_button {
    position: relative;
    z-index: 10;
    margin-top: $spacer * 0.75;
    border-radius: 4px;
    width: 15.625rem;
    height: 3.375rem;
    border: none;
    background-color: $rw_primary;
    color: $rw_white;
    font-weight: bolder;
    text-transform: uppercase;
    @include primaryBtn;
  }

  @include tablet {
    min-height: 10rem;

    .rw_media_upload_button {
      width: 10rem;
    }

    .rw_media_upload_text {
      display: none;
    }
  }
}
