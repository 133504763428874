// breakpoints
$mobile-width: 576px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-width: 1200px;

// colors
$rw_primary: #c6203e;
$rw_border: #c5c5c5;
$rw_light: #f1f1f1;
$rw_medium: #dbdbdb;
$rw_dark: #363636;
$rw_white: #fff;

// tabs
$tabs_padding: 1.75rem 1.5rem;
$tabs_background: $rw_light;
$tabs_background_hover: $rw_medium;
$tabs_border_color: $rw_border;
$tabs_border: 1px solid $rw_border;
$tabs_font_weight: 600;

//fonts
$base_font_family: inherit;
// mixins
@mixin mobile {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin primaryBtn {
  background-color: $rw_primary;
  &:hover {
    background-color: darken($rw_primary, 5%);
  }
}
