.industry-list {
  @extend .d-flex;

  flex-wrap: wrap;
  border: 1px solid $input;
  padding-bottom: $spacer*0.5;

  .industry-item {
    @extend .d-flex, .align-items-center;

    margin-left: $spacer*0.5;
    margin-top: $spacer*0.5;
    background-color: $primary;
    border-radius: 0;

    .industry-item-name {
      @extend .mb-0;

      padding: $spacer*0.5 $spacer*0.75;
      color: $white;
    }
    
    .industry-item-delete {
      @extend .px-2;

      background-color: darken($primary, 10%);
      height: 100%;
      border: none;

      svg {
        color: $white;
      }
    }
  }

  .industry-input {
    @extend .form-control;

    border: none;
  }
}