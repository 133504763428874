.publish-bar {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  position: sticky;
  top: $headerHeight;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  padding: $spacer * 2;
  margin-bottom: $spacer * 3;
  background: $white;

  @include media-breakpoint-down(sm) {
    button {
      padding: $spacer;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-wrap: unset;
    justify-content: flex-start;
    button {
      max-width: $spacer * 15;
      margin: 0 $spacer * 0.5;
    }
  }
}
