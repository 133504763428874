.presentation-preview {
  .modal-header {
    @extend .bg-dark;

    .modal-title {
      @extend .text-white;
      text-transform: uppercase;
    }

    .btn-modal-close {
      svg {
        color: $secondary;
      }
    }
  }
}
