.presentation-page {
  .team-members {
    .team-col {
      margin-bottom: $spacer * 3;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    figure {
      margin-bottom: $spacer * 2;
    }
    .team-member-title {
      @extend .text-purple, .bold-italic;
    }
    .team-member-bio {
      margin-top: $spacer * 2;
    }
  }
}
