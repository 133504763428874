.rw_media_library_search_container {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 1.25;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .rw_media_library_search_title {
    margin-bottom: 0;
  }

  .rw_media_library_search_wrapper {
    position: relative;
    width: 50%;
  }

  .rw_media_library_search_input {
    width: 100%;
    height: calc(3.5em);
    background-color: transparent;
    transition: background-color 0.15s ease;
    padding: $spacer $spacer * 0.75;
    border: 1px solid $rw_border;
    box-shadow: none;
    border-radius: $border-radius-sm;

    &::placeholder {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      color: #9a9a9a !important;
    }

    &:focus,
    &:active,
    &:focus-visible {
      outline: none;
      border: 1px solid $rw_primary !important;
    }
  }

  .rw_media_library_search_button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
    background-color: transparent;
    border: none;
  }

  @include mobile {
    flex-direction: column;

    .rw_media_library_search_wrapper {
      width: 100%;
    }

    .rw_media_library_search_input {
      width: 100%;
    }
  }
}
