.spark-alertbar {
  @extend .alert, .alert-dismissible;
  width: 100%;
  padding: $spacer;
}

.spark-alert {
  @extend .d-flex, .align-items-center, .p-4;
  border-radius: $border-radius;

  button {
    @extend .ps-4;
    border: none;
  }

  p {
    @extend .mb-0, .text-white;
  }
}
