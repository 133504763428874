.spark-modal {
  .modal-content {
    padding: $spacer * 2;

    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      max-height: 80vh;
      overflow-y: auto;
    }

    .modal-footer {
      border-top: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: $white;

      button {
        width: 100%;
        margin: $spacer * 0.5 0;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include media-breakpoint-up(lg) {
        flex-wrap: unset;

        button {
          width: $spacer * 15;
          margin: 0 $spacer * 0.5;
        }
      }
    }
  }
}

.document-modal {
  .modal-body {
    padding: 0;
    .rpv-default-layout__body {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

.import-info-modal {
  .sample-button {
    @extend .text-center;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
  }
}
