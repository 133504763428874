.rw-searchbar-container {
  position: relative;

  .rw-searchbar-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $spacer;
  }

  svg {
    fill: $primary;
  }
}
