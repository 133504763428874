.form-control {
  height: calc(3.5em);
  background-color: transparent;
  transition: background-color 0.15s ease;
  padding: $spacer $spacer * 0.75;

  &::placeholder {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    color: #9a9a9a !important;
  }

  &.is-valid,
  &.is-invalid {
    background-position: right 2rem center;
  }
}

textarea.form-control {
  height: unset;
}

.required-label {
  @extend .fst-italic, .text-primary;

  position: absolute;
  bottom: -10px;
  left: 0;
}

.multi-select-container {
  @extend .d-flex;

  .multi-select {
    width: 100%;

    .multi-select__value-container {
      align-items: center;
      height: calc(3.5em);
      background-color: transparent;
      transition: background-color 0.15s ease;
      // padding: $spacer $spacer*0.75;

      .multi-select__multi-value {
        background-color: $primary;
        padding: $spacer * 0.25;
        font-family: "Montserrat";
        font-weight: 600;

        .multi-select__multi-value__label {
          color: $white;
        }

        .multi-select__multi-value__remove {
          svg {
            fill: $white;
            height: $spacer;
            width: $spacer;
          }
        }
      }

      .multi-select__placeholder {
        font-family: "Montserrat";
        font-style: italic;
        font-weight: 400;
      }
    }
  }

  .multi-select-btn {
    @extend .d-flex,
      .align-items-center,
      .justify-content-center,
      .bg-primary,
      .text-white,
      .ms-3,
      .button-font;

    text-decoration: none;
    width: 150px;
  }
}

.ql-editor {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: $spacer;

  &::before {
    color: #9a9a9a !important;
  }

  p {
    margin-bottom: $spacer * 0.5;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.form-switch {
  @extend .my-0, .d-flex, .align-items-center;
  label {
    @extend .mx-2;
    line-height: $spacer * 1.25;
  }
  .form-check-input {
    height: $spacer * 1.25;
    width: $spacer * 2.5;
  }
}
