.presentation-page {
  .services {
    .service-row {
      @extend .bg-secondaryBackground, .align-items-center;
      padding: $spacer * 1.25 $spacer * 2.5;
      margin-bottom: $spacer * 2.25;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .service-title-col {
        @extend .d-flex;

        .contact-index {
          padding-right: $spacer;
          padding-top: $spacer;
          padding-bottom: $spacer;
          @include media-breakpoint-up(sm) {
            padding-right: $spacer * 2;
          }
        }

        .service-title {
          border-left: 1px solid $primary;
          padding-left: $spacer;
          padding-top: $spacer;
          padding-bottom: $spacer;
        }
      }
      .service-description-col {
        padding-top: $spacer;

        .service-link {
          text-decoration: none;
          color: $primary;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
