.rw_media_library {
  background-color: #fff;
  font-weight: 400;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.rw_media_library_inner_container {
  padding: 4rem 5rem;

  @include large {
    padding: 2rem;
  }

  @include tablet {
    padding: 1rem;
  }
}

.rw_media_Library_image_container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 1.5rem;
  border: 2px solid $rw_border;

  @include tablet {
    padding: 1rem;
    flex-direction: column;
  }

  .rw_media_list {
    flex: 3;
    order: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;

    @include large {
      grid-template-columns: repeat(3, 1fr);
      // flex: 1 0 calc(25% - 1rem);
      // max-width: calc(25% - 1rem);
      // min-width: calc(25% - 1rem);
    }

    @include tablet {
      order: 2;
    }

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      // flex: 1 0 calc(50% - 1rem);
      // max-width: calc(50% - 1rem);
      // min-width: calc(50% - 1rem);
    }

    .rw_media_list_item {
      padding-bottom: 1rem;
      --aspect-ratio: 1 / 1;
      // flex: 1 0 calc(20% - 1rem);
      // max-width: calc(20% - 1rem);
      // min-width: calc(20% - 1rem);
      position: relative;

      @include large {
        // flex: 1 0 calc(25% - 1rem);
        // max-width: calc(25% - 1rem);
        // min-width: calc(25% - 1rem);
      }

      @include tablet {
        // flex: 1 0 calc(33% - 1rem);
        // max-width: calc(33% - 1rem);
        // min-width: calc(33% - 1rem);
      }

      @include mobile {
        // flex: 1 0 calc(50% - 1rem);
        // max-width: calc(50% - 1rem);
        // min-width: calc(50% - 1rem);
      }

      input {
        display: none;
      }

      label {
        border: 2px solid #eee;
        height: auto;
        width: 100%;
        display: block;
        text-align: center;
        position: relative;
        cursor: pointer;
        // background-position: center;
        // background-size: contain;
        // background-repeat: no-repeat;
      }

      p {
        margin-bottom: 0;
        padding: $spacer * 0.25;
        position: absolute;
        bottom: 0;
        width: 100%;
        word-wrap: break-word;
        font-size: $spacer * 0.75;
        background-color: rgba(255, 255, 255, 0.66);
      }

      // label:before {
      //   background-color: white;
      //   color: white;
      //   content: " ";
      //   display: block;
      //   border-radius: 50%;
      //   border: 1px solid grey;
      //   position: absolute;
      //   top: -5px;
      //   left: -5px;
      //   width: 25px;
      //   height: 25px;
      //   text-align: center;
      //   line-height: 28px;
      //   transition-duration: 0.4s;
      //   transform: scale(0);
      // }

      label img {
        max-width: 100%;
        // margin: 0 auto;
        // object-fit: cover;
        // transition-duration: 0.2s;
        // transform-origin: 50% 50%;

        // @include large {
        //   height: 100px;
        // }

        // @include tablet {
        //   height: 100px;
        // }

        // @include mobile {
        //   height: 75px;
        // }
      }

      :checked + label {
        border: 2px solid $rw_primary;
      }

      // :checked + label:before {
      //   content: "✓";
      //   background-color: grey;
      //   transform: scale(1);
      // }

      :checked + label img {
        // transform: scale(0.9);
        box-shadow: 0 0 5px #333;
        // z-index: -1;
      }
    }
  }
}
