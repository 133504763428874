.sidebar-wrapper {
  display: none;
  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    display: block;
    padding-top: 102px;
    width: 15rem;
    left: 0;
    background-color: #363636;
    color: #fff;
    margin-left: 0;
    height: 100vh;
    transition: all 0.15s linear;

    .sidebar-container {
      // height: 100%;
      // background-color: $medium;

      margin-right: 0;
      margin-left: auto;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -o-box-sizing: border-box;
      -ms-box-sizing: border-box;
      box-sizing: border-box;

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $medium !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $primary !important;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $primary !important;
      }
    }
  }

  &.menu-collapsed {
    width: $spacer * 4;

    .create-new-link {
      padding: $spacer * 1.25 $spacer * 1.5 !important;
      .btn-span {
        @extend .d-none;
      }

      svg {
        @extend .d-block;
      }
    }

    .menu-control {
      padding: $spacer * 1.25 $spacer * 1.5 !important;
      .btn-span {
        @extend .d-none;
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    .nav-link {
      padding: $spacer * 1.25 $spacer * 1.5 !important;
      .span {
        font-size: 0;
        @extend .d-none;
      }
    }
  }

  .nav {
    @extend .d-flex, .justify-content-end, .p-0;

    > * {
      height: 56px;
    }

    .create-new-link {
      @extend .text-white, .nav-link, .btn;

      background-color: $primary;
      font-size: $spacer * 0.875;
      font-family: "Montserrat";
      font-weight: 500;
      width: 100%;
      border: none;

      &:focus {
        border: none;
        outline: none;
      }

      svg {
        display: none;
      }

      .btn-span {
        @extend .mx-auto;
      }
    }

    .menu-control {
      @extend .text-white, .nav-link;

      font-size: $spacer * 0.875;
      font-family: "Montserrat";
      font-weight: 500;
      width: 100%;
      border: none;

      &:focus {
        border: none;
        outline: none;
      }
    }

    .nav-link {
      @extend .text-white,
        .nav-font,
        .d-flex,
        .justify-content-between,
        .align-items-center;

      padding: $spacer $spacer * 1.5;
      width: 100%;
      position: relative;

      &.active {
        .span {
          font-weight: 800;
        }

        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 100%;
          top: 0;
          left: 0;
          background-color: $secondary;
        }
      }

      &.active,
      &.open,
      &:hover {
        background-color: $activeDark;
      }

      .span {
        width: 175px;
        padding-left: $spacer * 1.25;
      }
    }
  }
}
