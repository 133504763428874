.presentation-side-navigation {
  padding-right: $spacer * 2;
  position: sticky;
  top: 0;

  .navigation-title {
    font-weight: 800 !important;
    padding-bottom: $spacer * 2;
    margin-bottom: $spacer * 2;
    border-bottom: $listBorder solid 1px;
  }

  ul {
    padding-left: 0;
  }

  .nav-link {
    a {
      @extend .text-dark;
      text-decoration: none;

      &.active {
        color: $primary !important;
      }
    }
  }
}
