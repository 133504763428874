.spark-dropdown {
  position: relative;

  .spark-dropdown-menu.hide {
    display: none;
  }

  .spark-dropdown-menu {
    background-color: $white;
    padding: $spacer;
    position: absolute;
    top: 3rem;
    width: 300px;
    z-index: 5;
    box-shadow: $box-shadow;
    border-radius: $border-radius-sm;
    right: -$spacer;
    left: unset !important;
    width: 300px;
    @include media-breakpoint-up(md) {
      right: -$spacer * 2.5;
    }

    .list-arrow {
      -webkit-filter: drop-shadow(-2px -2px 3px rgba(0, 0, 0, 0.05));
      filter: drop-shadow(-2px -2px 3px rgba(0, 0, 0, 0.05));
      width: 0;
      height: 0;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 32px 32px 0 0;
      border-color: #fff transparent transparent transparent;
      right: $spacer * 0.875;
      top: -$spacer;
      z-index: 10;
      position: absolute;

      @include media-breakpoint-up(md) {
        right: $spacer * 2.325;
      }
    }
  }
}
