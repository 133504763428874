.presentation-page {
  .contact-information {
    .contact-info-row {
      @extend .bg-secondaryBackground;
      padding: $spacer * 1.25 $spacer * 2.5;
      margin-bottom: $spacer * 2.25;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .contact-info-name {
        @extend .mb-2;
      }

      .contact-info-title {
        @extend .text-purple, .bold-italic, .mb-0;
      }

      .title-col {
        @extend .d-flex, .flex-column, .justify-content-center;
      }
      .contact-col {
        @extend .d-flex, .flex-column, .justify-content-between;
      }

      .title-col {
        position: relative;
        @include media-breakpoint-down(lg) {
          padding-bottom: $spacer * 2;
          &::after {
            background-color: $input;
            content: "";
            position: absolute;
            bottom: $spacer;
            width: $spacer * 15;
            height: 1px;
            z-index: 10;
          }
        }
      }

      .contact-col {
        @include media-breakpoint-up(lg) {
          border-left: 1px solid $input;
          padding-left: $spacer * 2;
        }
      }

      .contact-info-contact {
        margin-bottom: $spacer * 0.5;
        svg {
          margin-right: $spacer;
        }
      }
    }
  }
}
