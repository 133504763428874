.presentation-page {
  .case-studies {
    .case-study-col {
      margin-bottom: $spacer * 3;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    figure {
      margin-bottom: $spacer * 2;
    }

    .case-study-title {
      margin-bottom: $spacer;
    }

    .case-studies-documents {
      @extend .d-flex, .align-items-center, .mb-2;

      button {
        @extend .text-primary;
        max-width: 85%;
        text-align: left;
        white-space: normal;
        word-wrap: break-word;
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
      a {
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
      svg {
        margin-right: $spacer;
      }
    }
  }
}
