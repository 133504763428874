.rw_media_library_file_info {
  font-weight: 300;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  width: 25%;
  flex: 1;
  order: 2;
  color: $rw_dark;
  position: relative;

  @include tablet {
    order: 1;
    width: 100%;
    margin-left: 0;
    padding: 1.5rem 0;
  }
  .rw_media_library_file_info_inner {
    position: sticky;
    top: $headerHeight;
  }
  .rw_media_library_file_info_title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .rw_media_library_file_info_fileName {
    margin-bottom: 1rem;
    word-break: break-all;
  }

  .rw_media_library_file_info_media_info {
    display: flex;
    margin-bottom: 1rem;
    img {
      margin-right: 0.5rem;
    }

    .rw_media_library_file_info_fileData {
      margin-bottom: 0;
    }
  }

  .rw_media_library_file_info_input_group {
    margin: 1rem 0;

    label {
      width: 100%;
      margin-bottom: 0.5rem;
      font-style: italic;
    }

    input {
      width: 100%;
      height: calc(3.5em);
      background-color: #fff;
      transition: background-color 0.15s ease;
      padding: $spacer $spacer * 0.75;
      border: 1px solid $rw_border;
      box-shadow: none;
      border-radius: $border-radius-sm;

      &::placeholder {
        font-family: "Montserrat";
        font-style: italic;
        font-weight: 400;
        color: #9a9a9a !important;
      }

      &:focus,
      &:active,
      &:focus-visible {
        outline: none;
        border: 1px solid $rw_primary !important;
      }
    }
  }

  .rw_media_library_file_insert {
    .rw_media_library_file_insert_button {
      position: relative;
      z-index: 10;
      margin-top: $spacer * 0.75;
      width: 100%;
      height: 3.375rem;
      border: none;
      background-color: $rw_primary;
      color: $rw_white;
      font-weight: bolder;
      text-transform: uppercase;
      @include primaryBtn;
    }
  }
}

.rw_media_library_file_update {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  button {
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
  }
}

.rw_media_library_update_btn {
  color: $rw_dark;
}

.rw_media_library_delete_btn {
  color: $rw_primary;
}
