.presentation-list {
  .presentation-list-card {
    box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.07);
    padding: $spacer * 1.5;
    margin-bottom: $spacer * 1.5;
    border: none;
    position: relative;
    border-radius: $border-radius-sm;

    .presentation-list-label {
      @extend .d-flex, .align-items-center, .justify-content-center;
      border-radius: $border-radius-sm 0 0 $border-radius-sm;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50px;
      background-color: #333;
      color: #fff;

      p {
        @extend .mb-0;
        transform: rotate(-90deg);
        font-size: $spacer * 0.75;
        white-space: nowrap;
      }
    }

    .presentation-list-container {
      @extend .d-flex, .justify-content-between, .align-items-start;

      padding-left: 50px;

      .presentation-list-title-link {
        color: $dark;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        .presentation-list-title {
          @extend .mb-2;
        }
      }

      .presentation-list-details {
        @extend .d-flex, .flex-column, .flex-md-row;

        .presentation-list-created-date {
          @include media-breakpoint-up(md) {
            margin-right: $spacer;
          }
        }
      }
    }
  }
}
