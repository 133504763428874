.rws-toggle {
  .switch {
    position: relative;
    display: inline-block;
    width: $spacer*3;
    height: $spacer*1.5;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: $spacer*1.5;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: $spacer;
    width: $spacer;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: $primary;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX($spacer*1.5);
    -ms-transform: translateX($spacer*1.5);
    transform: translateX($spacer*1.5);
  }
}