#footer {
  background-color: $dark;
  min-height: $footerHeight;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  padding-bottom: $spacer;
  padding-top: $spacer;

  svg {
    margin: 0 !important;
  }

  .footer-links {
    @extend .my-auto;
    @include media-breakpoint-down(sm) {
      width: 50%;
    }

    .nav {
      @extend .align-items-sm-center,
        .align-items-end,
        .flex-column,
        .flex-sm-row,
        .justify-content-end;

      p {
        padding: $spacer * 0.5 $spacer;
        color: $white;
      }

      .nav-link {
        color: $white;
      }
    }
  }
}

#login-footer {
  background-color: $dark;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;

  > .container > div {
    min-height: $footerHeight;
    padding-bottom: $spacer;
    padding-top: $spacer;
  }

  .logo-bar {
    @include media-breakpoint-up(md) {
      margin-left: 5rem;

      svg {
        margin-right: 3rem;
      }
    }

    @include media-breakpoint-down(md) {
      svg {
        margin: 0 1.5rem;
      }
    }

    svg {
      cursor: pointer;
    }

    svg:nth-child(2) {
      position: relative;
      right: -0.725rem;
      margin-left: -0.725rem;
    }

    svg:first-child {
      opacity: 0.7;
      transition: opacity 0.25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  // svg {
  //   margin: 0 !important;
  //   @include media-breakpoint-down(md) {
  //     margin: 0 auto !important;
  //   }
  // }

  .footer-links {
    @extend .d-flex,
      .flex-column,
      .justify-content-between,
      .align-items-center,
      .align-items-md-end;

    .nav {
      @extend .justify-content-md-end;

      .nav-link {
        color: $white;
      }
    }

    .copyright {
    }
  }
}
