.edit-bar {
  @extend .d-flex, .justify-content-md-end;

  position: relative;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: $spacer * 3;
    right: $spacer * 3;
  }

  *:not(:last-child) {
    margin-right: $spacer;
  }
}
