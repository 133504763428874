.presentation-page {
  .testimonials {
    .carousel {
      .carousel-inner {
        .slide-item {
          min-height: $spacer * 20;
          background-color: $dark;

          .slide-container {
            @extend .d-flex,
              .flex-column,
              .justify-content-center,
              .align-items-center;
            padding: $spacer * 3 $spacer * 5;

            .testimonial-testimonial {
              @extend .mt-2;
              * {
                @extend .text-white, .text-center, .light-italic;
              }
            }
            .testimonial-citation {
              @extend .text-white, .text-center, .light-italic, .mb-0;
              text-transform: uppercase;
            }
            svg {
              margin-bottom: $spacer * 1.5;
            }
            .testimonial-image {
              border-radius: 50%;
            }
          }
        }
      }
      //   position: relative;

      //   .carousel__inner-slide {
      //     @extend .bg-dark,
      //       .text-white,
      //       .d-flex,
      //       .flex-column,
      //       .align-items-center,
      //       .justify-content-center;

      //     padding: $spacer * 2 $spacer * 4;

      //   }

      //   .carousel__dot-group {
      //     position: absolute;
      //     bottom: 10px;
      //     left: 50%;
      //     transform: translateX(-50%);
      //   }
    }
  }
}
