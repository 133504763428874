.add-media-button {
  @extend .mb-4;

  .add-media-button-container {
    .show-image-container {
      position: relative;

      .show-image-buttons {
        // position: absolute;
        // top: 0;
        // // right: 0;
        // width: 100%;
        // display: flex;
        // justify-content: flex-end;
        // align-items: flex-start;
        button {
          border-radius: 50%;
        }

        svg {
          color: #000;
          fill: #000;
        }
      }
    }
  }
}

.media-modal {
  .modal-footer {
    border-top: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: $white;
    padding: 0 4rem 2rem 0;

    @include media-breakpoint-down(lg) {
      padding: 2rem;
    }

    @include media-breakpoint-down(md) {
      padding: 1rem;
    }

    button {
      width: 100%;
      margin: $spacer * 0.5 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: unset;

      button {
        width: $spacer * 15;
        margin: 0 $spacer * 0.5;
      }
    }
  }
}
