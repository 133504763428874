* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: $spacer * 0.875;
  background-color: $background;
  color: $dark;
}

#root {
  height: 100vh;
  overflow: auto;
  position: relative;
}

.main-wrapper {
  @extend .container-fluid;
  // margin-top: $headerHeight;
  position: relative;
  padding: $spacer * 0.5;

  @include media-breakpoint-up(sm) {
    padding: $spacer * 3.75 $spacer * 2.5;
    margin-bottom: 0;
  }
}

.dropdown-btn {
  border: 1px solid $input;
}

#main-container {
  transition: all 0.15s linear;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer * 15;

    &.menu-collapsed {
      padding-left: $spacer * 4;
    }
  }
}

#login-container {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: $footerHeight;
}

.content-area {
  padding: $spacer * 2;
  background: $background;
  position: relative;
  height: 100%;
  margin-right: 0;
  margin-left: auto;
  overflow: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

  @include media-breakpoint-up(lg) {
    margin-left: $sidebarWidth;
    padding: $spacer * 5;
  }
}

// font classes
.nav-font {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 3.75px;
  line-height: 38px;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 2.5px;
  line-height: 30px;
}

h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat";
}

.display-1 {
  font-size: $spacer * 1.875;
  font-weight: 500;
  line-height: 30px;
}

.display-2 {
  font-size: $spacer * 1.875;
  font-weight: 800;
  line-height: 30px;
}

.display-3 {
  font-size: $spacer;
  font-weight: 600;
  line-height: 30px;
}

.mb-medium {
  margin-bottom: 2.5rem;
}

.button-font {
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 600;
}

.light-font {
  font-family: "Montserrat";
  font-weight: 300;
}

.bold-italic {
  text-transform: uppercase;
  font-weight: 800;
  line-height: 30px;
  font-style: italic;
  font-family: "Montserrat";
}

.light-italic {
  font-weight: 400;
  line-height: 30px;
  font-style: italic;
  font-family: "Montserrat";
}

// component classes
.component-card {
  box-shadow: $box-shadow;
  position: relative;
  padding: $spacer;
  margin-bottom: $spacer;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 3;
    padding: $spacer * 2.5;
    border: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.spinner-container {
  @extend .d-flex, .justify-content-center, .align-items-center, .py-5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: $spacer * 10;
  width: $spacer * 10;
  background-color: $white;
}

// buttons

button.transparent {
  border: none;
  background-color: transparent;
}

.btn {
  @extend .button-font;
}

.btn-lg {
  @include media-breakpoint-up(sm) {
    width: $spacer * 19;
  }
}

.button-import {
  @extend .d-none,
    .d-lg-flex,
    .justify-content-start,
    .justify-content-lg-end,
    .align-items-center;

  margin-top: $spacer * 1.5;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .svg-btn {
    svg {
      fill: $dark;
      &:hover {
        fill: $white !important;
      }
    }
  }

  .button-import-input {
    position: relative;
  }
}

.btn-import {
  @extend .btn,
    .btn-lg,
    .btn-outline-medium,
    .d-flex,
    .align-items-center,
    .justify-content-center;

  position: relative;
  z-index: 10;

  svg {
    margin-right: $spacer * 1.5;

    path {
      fill: $medium;
      transition: $transition-base;
    }
  }

  &:hover {
    svg {
      path {
        fill: $white !important;
      }
    }
  }
}
.info-btn-wrapper {
  width: $spacer * 1.25;
  height: $spacer * 1.25;
  margin-left: $spacer;

  .btn-info {
    border: 1px solid $dark;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: 12px;

    &:hover {
      background-color: $dark;
      color: $white;
    }
  }
}

.import-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
