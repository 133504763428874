.dashboard {
  .dashboard-title {
    margin-bottom: $spacer;
  }

  // .presentation-module-list {
  //   ul {
  //     overflow: auto;
  //     list-style: none;
  //     padding-left: 0;

  //     .presentation-edit-module {
  //       padding-top: $spacer * 1.5;
  //       padding-bottom: $spacer * 1.5;
  //     }
  //   }
  // }
}
