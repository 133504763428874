.presentation-template {
  .presentation-template-card {
    @extend .h-100, .justify-content-between;

    background-color: rgba(255, 255, 255, 0.85);
    border: 0;
    padding: $spacer * 4 $spacer * 2.5;

    &::before {
      position: absolute;
      content: "";
      border-radius: $border-radius $border-radius 0 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 10px;
      transition: all 0.8s linear;
    }

    &.template-welcome {
      &::before {
        background-color: $welcome;
      }
    }

    &.template-proposal {
      &::before {
        background-color: $proposal;
      }
    }

    &.template-blank {
      &::before {
        background-color: $blank;
      }
    }

    &.template-custom {
      &::before {
        background-color: $primary;
      }
    }

    h2 {
      @extend .text-center;

      margin-bottom: $spacer * 2.5;
    }

    p {
      margin-bottom: $spacer * 2.5;
    }

    .edit-icons {
      @extend .d-flex;

      margin-bottom: $spacer * 2.5;

      svg {
        @extend .me-3;
      }
    }

    .btn {
      @extend .w-100;
    }
  }
}
