.presentation-page {
  .documents {
    .documents-row {
      @extend .bg-secondaryBackground, .align-items-center;
      padding: $spacer * 1.25 $spacer * 2.5;
      margin-bottom: $spacer * 2.25;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .document-title-col {
        position: relative;

        h3 {
          word-wrap: break-word;
          margin-bottom: 0;
        }

        @include media-breakpoint-down(lg) {
          padding-bottom: $spacer * 2;
          &::after {
            background-color: $input;
            content: "";
            position: absolute;
            bottom: $spacer;
            width: $spacer * 15;
            height: 1px;
            z-index: 10;
          }
        }
      }

      .document-download-col {
        @include media-breakpoint-up(lg) {
          padding-right: $spacer * 2;
        }
      }

      .document-download-col {
        @extend .d-flex, .align-items-center;

        @include media-breakpoint-up(lg) {
          border-left: 1px solid $input;
          padding-left: $spacer * 2;
        }

        svg {
          margin-right: $spacer;
        }

        button {
          white-space: normal;
          word-wrap: break-word;
          text-decoration: none;
          color: $primary;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
